@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Gerbera";
  src: local("Gerbera"), url("assets/fonts/Gerbera.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gerbera";
  src: local("Gerbera"), url("assets/fonts/Gerbera-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Sofia Pro"),
    url("./assets/fonts/SofiaPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Sofia Pro"),
    url("./assets/fonts/SofiaPro-Bold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "Sofia Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  position: relative;
}

.app-menu {
  color: #313d53;
  height: auto;
  width: 100%;
  background-color: #fff;
  z-index: 200;

  transform: translateX(100%);
  transition: 0.3s;

  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.menu-open {
  transform: translateX(0%);
}

.carousel .control-dots {
  color: #289e5b;
}

.carousel .control-dots .dot {
  width: 4px !important;
  height: 4px !important;
  background: #849e8f !important;
}
.carousel .control-dots .dot.selected {
  width: 20px !important;
  border-radius: 4px !important;
  background: #289e5b !important;
  box-shadow: none !important;
}
.carousel .slide {
  text-align: left !important;
}

.carousel.carousel-slider {
  padding-bottom: 18px;
}

.bottom-gradient {
  background: linear-gradient(to bottom, transparent 65%, #27965340);
}

@layer components {
  .section {
    max-width: 1440px;
      margin: auto;
    @apply p-4 lg:py-[80px] lg:px-[120px];
  }
    .section.no-top {
      @apply  lg:pt-0;
    }
  
    .section h4 {
      @apply block font-bold text-2xl leading-[32px] lg:leading-[57px] lg:text-5xl text-[#213A2B]  mb-5 lg:mb-[60px];
    }

    .section-inner + .section-inner {
      @apply pt-[32px] lg:pt-[80px];
    }
}